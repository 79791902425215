import apiAction from '../apiActionCreator';

const type = 'GET_USER_ELECTRONIC_SIGN';
// getUserElectronicSign

const config = {
  url: '/v1/order/check-agreements',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
