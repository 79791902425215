import { isClient } from './utils';

export const getSearchParam = (param, url) => {
  if (url.includes('country=') && url.includes('Token=') && url.includes('/?Token')) {
    return new URLSearchParams(new URL(url.replace('/?Token', '&Token')).search).get(param);
  }

  return new URLSearchParams(new URL(url).search).get(param);
};

export const checkSitePage = (expectedRoute) =>
  isClient && window.location.href.includes(expectedRoute);
