import apiAction from '../apiActionCreator';

const type = 'GET_PLANS';

const config = {
  url: '/plan/list',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
