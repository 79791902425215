import apiAction from '../apiActionCreator';

const type = 'UPDATE_USER_CURRENCY';

const config = {
  url: '/v1/user/preferred-currency',
  actionType: type,
  sendFormat: 'json',
  method: 'PUT',
};

export default apiAction(config);
