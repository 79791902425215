import apiAction from '../apiActionCreator';

const type = 'ORDERS_PURCHASE';

const config = {
  url: '/v1/order/purchase',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
