import apiAction from '../apiActionCreator';

const type = 'GET_SIGNED_AGREEMENT';

const config = {
  url: '/agreement/external/', // GET /agreement/external/:signingExternalId
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
