import axios from 'axios';
import { API_URL } from './utils';
import { patchRequestHeadersInterceptor } from './tokenHandler';

const instance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

instance.interceptors.request.use(patchRequestHeadersInterceptor, (error) => Promise.reject(error));

export default instance;
