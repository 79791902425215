import { isClient, isUserLogged } from '../../helpers/utils';

export const TOKEN_EXPIRED = 'AXIOS_TOKEN_EXPIRED';

export const tokenExpiredAction = () => ({ type: TOKEN_EXPIRED });

export function getTokens() {
  if (isClient && isUserLogged()) {
    return {
      refreshToken: localStorage.getItem('RT'),
      accessToken: localStorage.getItem('AT'),
      redirectionToken: localStorage.getItem('RED_TOKEN'),
      beBackOfficeToken: localStorage.getItem('BT'),
    };
  }
  return {};
}

export function setTokens(data) {
  if (isClient) {
    localStorage.setItem('RT', data.refreshToken);
    localStorage.setItem('AT', data.accessToken);
    localStorage.setItem('BT', data.beBackOfficeToken);
    localStorage.setItem('RED_TOKEN', data.redirectionToken);
  }
}

export function patchRequestHeadersInterceptor(config = {}) {
  const { url, headers = {} } = config;

  if (url === '/auth/refresh-tokens') {
    headers.Authorization = `Bearer ${getTokens().refreshToken}`;
  } else if (url === '/auth/signin-final') {
    headers.Authorization = `Bearer ${getTokens().redirectionToken}`;
  } else {

    const token = getTokens().accessToken;

    headers.Authorization = token ?  `Bearer ${token}` : undefined;
  }
  return { ...config, headers };
}
