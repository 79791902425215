import { countries } from 'countries-list';
// eslint-disable-next-line import/no-cycle
import { isClient } from './utils';

export const europeCountries = [
  'hu', 'by', 'at', 'rs', 'ch', 'de', 'va', 'ad', 'bg', 'gb', 'fr', 'me', 'lu', 'it', 'dk', 'fi', 'sk', 'no', 'ie',
  'es', 'mt', 'ua', 'hr', 'md', 'mc', 'li', 'pl', 'is', 'sm', 'ba', 'al', 'lt', 'mk', 'sl', 'ro', 'lv', 'nl', 'ru', 'ee',
  'be', 'cz', 'gr', 'pt', 'se'
];

export const latinAmericaCountries = [
  'mx', 'gt', 'hn', 'sv', 'sv', 'ni', 'cr', 'pa', 'co', 've', 'ec', 'pe', 'bo', 'br', 'py', 'cl', 'ar', 'uy', 'cu',
  'ht', 'do', 'pr', 'ht',
];

const gbInstanceCountries = ['gb'];
const befreedomInstanceCountries = ['us'];
const frInstanceCountries = ['fr'];

const betoinspireProdCountries = [
  'be',
  'bg',
  'cz',
  'dk',
  'de',
  'ee',
  'ie',
  'gr',
  'es',
  'fr',
  'hr',
  'it',
  'cy',
  'lv',
  'lt',
  'lu',
  'hu',
  'mt',
  'nl',
  'at',
  'pl',
  'pt',
  'ro',
  'si',
  'sk',
  'fi',
  'se',
];

const brInstanceCountries = [
  'br',
  'in',
  'ng',
  'ke',
  'za',
  'ma',
  'ug',
  'gh',
  'cm',
  'ci',
  'bj',
  'tg',
  'cg',
  'ga',
  'vn',
  'pk',
  'id',
  'tr',
  'th',
  'pf',
  'ne',
  'cd',
  'dz',
  'sa',
  'np',
  'lk',
  'ph',
  'pg',
  'zw',
  'mg',
  'na',
  'vg',
  'jm',
  'gy',
  'hk',
  'my',
  'bb',
  'tt',
  'kr',
  'ht',
  'dm',
  'ag',
  'lc',
  'sr',
  'gd',
  'vc',
  'fj',
  'bw',
  'zw',
  'cd',
];

const getOnlineLineInstanceCountries = () =>
  [...Object.keys(countries)]
    .filter(
      (item) =>
        ![
          ...gbInstanceCountries,
          ...frInstanceCountries,
          ...brInstanceCountries,
          ...befreedomInstanceCountries,
        ].includes(item.toLowerCase())
    )
    .map((country) => country.toLowerCase());

export const getCountryFilterParam = (param = null) => {
  if (process.env.GATSBY_INSTANCE_TYPE === 'stg') {
    return [];
  }

  switch (param || process.env.GATSBY_INSTANCE_NAME) {
    case 'br': {
      return brInstanceCountries;
    }
    case 'gb': {
      return gbInstanceCountries;
    }
    case 'fr': {
      return frInstanceCountries;
    }
    case 'befreedom':
    case 'onlineEra': {
      return befreedomInstanceCountries;
    }
    case 'betoinspireprod': {
      return betoinspireProdCountries;
    }
    case 'onlineLine':
    case 'beunleash': {
      return [...getOnlineLineInstanceCountries(), 'other'];
    }
    default: {
      return [];
    }
  }
};

export const getLinkToCorrectShopByCountry = (country = '') => {
  if (brInstanceCountries.includes(country)) {
    return process.env.GATSBY_BRAZIL_PROD_URL;
  }
  if (gbInstanceCountries.includes(country)) {
    return process.env.GATSBY_GB_PROD_URL;
  }
  if (frInstanceCountries.includes(country)) {
    return process.env.GATSBY_FR_PROD_URL;
  }
  if (befreedomInstanceCountries.includes(country)) {
    return process.env.GATSBY_BEFREEDOM_PROD_URL;
  }
  if (betoinspireProdCountries.includes(country)) {
    return process.env.GATSBY_BETOINSPIRE_PROD_URL;
  }

  return process.env.GATSBY_ONLINE_LINE_PROD_URL;
};

export const checkSearchableSelect = () => {
  switch (process.env.GATSBY_INSTANCE_NAME) {
    default: {
      return true;
    }
  }
};

export const availableEnrollers = ['yafe', 'jama'];
