import { createSelector } from 'reselect';

const selectGlobal = (state) => state.global;

export const selectChosenProduct = createSelector(selectGlobal, (global) => global.selectedProduct);

export const selectShoppingCart = createSelector(selectGlobal, (global) => global.shoppingCart);

export const selectLoader = createSelector(selectGlobal, (global) => global.isLoading);
export const selectSubmitCount = createSelector(selectGlobal, (global) => global.submitCount);
export const selectLanguages = createSelector(selectGlobal, (global) => global.languages);
export const selectChozenLanguage = createSelector(
  selectGlobal,
  (global) => global.selectedLanguage
);

export const selectRegisterValues = createSelector(
  selectGlobal,
  (global) => global.registrationValues
);
export const selectCouponId = createSelector(selectGlobal, (global) => global.selectedCoupon);
export const selectLastAddedAddon = createSelector(selectGlobal, (global) => global.lastAddedAddon);
export const selectChosenPaymentMethod = createSelector(
  selectGlobal,
  (global) => global.selectedPaymentMethod
);
export const selectAppliedCoupons = createSelector(selectGlobal, (global) => global.appliedCoupons);
export const selectPreselectedCoupon = createSelector(
  selectGlobal,
  (global) => global.preselectedCoupon
);
export const selectAddonsModalInfo = createSelector(
  selectGlobal,
  (global) => global.addonsModalInfo
);
export const selectModal = createSelector(selectGlobal, (global) => global.modal);
export const selectModalError = createSelector(selectGlobal, (global) => global.modalError);

export const selectEmailBannerFlag = createSelector(selectGlobal, (global) => global.isWrongEmail);
export const selectUserCountry = createSelector(selectGlobal, (global) => global.userCountry);
export const selectIsAnnually = createSelector(selectGlobal, (global) => global.isAnnually);
export const selectButtonLoaderType = createSelector(
  selectGlobal,
  (global) => global.buttonLoaderType
);
export const selectPhoneVerificationButtonState = createSelector(
  selectGlobal,
  (global) => global.allowToSkipPhoneVerification
);

export const selectAvailablePaymentMethods = createSelector(
  selectGlobal,
  (global) => global.availablePaymentMethods
);

export const selectShouldUpdateAgreement = createSelector(
  selectGlobal,
  (global) => global.shouldUpdateAgreement
);

export const selectShowCookiesBanner = createSelector(selectGlobal, (global) => global.showCookiesBanner);
