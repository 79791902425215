import { call, select, takeEvery, put } from 'redux-saga/effects';
import { isNewIbiUsaInstance } from '../helpers/utils';
import { selectUserCountry } from '../store/selectors/global';
import { selectEntityUserDetails } from '../store/selectors/entities';
import * as AppConstants from '../store/constants';
import { setCookiesBanner } from '../store/actions';

export const cookieScriptShow = !isNewIbiUsaInstance

const addCookiesScript = () => {
  const script = document.createElement("script");
  script.src = "https://app.termly.io/embed.min.js";
  script.setAttribute('data-auto-block', 'on');
  script.setAttribute('data-website-uuid', process.env.GATSBY_TERMLY_ID);
  script.async = true;
  document.body.appendChild(script);
};

function* countryChecker() {
  const userData = yield select(selectEntityUserDetails);
  const selectedCountry = yield select(selectUserCountry);
  const isItaly = selectedCountry === 'IT';
  const isItalyUserData = userData?.country === 'IT';
  if (typeof window !== 'undefined' && (isItaly || isItalyUserData) && cookieScriptShow) {
    yield put(setCookiesBanner(false));
    yield call(addCookiesScript);
  }
}

export default function* termlySaga() {
  yield takeEvery(AppConstants.SET_USER_COUNTRY, countryChecker);
}
