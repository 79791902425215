import fallenRequests from './fallenRequests';
import { tokenExpiredAction } from './tokenHandler';
import refreshToken from './actions/refreshToken';
import getToken from './actions/getToken';
import getTokenFinal from './actions/getTokenFinal';
import recoverEmail from './actions/recoverEmail';
import passwordReset from './actions/passwordReset';
import forgotUserName from './actions/forgotUserName';
import signUp from './actions/signUp';
import getMetaData from './actions/getMetaData';

export const SUCCESS_SUFFIX = '/SUCCESS';
export const ERROR_SUFFIX = '/ERROR';
export const CANCEL_SUFFIX = '/CANCEL';

export const getActionTypes = (
  action,
  { errorSuffix = ERROR_SUFFIX, successSuffix = SUCCESS_SUFFIX, cancelSuffix = CANCEL_SUFFIX } = {}
) => {
  let types;
  if (typeof action.type !== 'undefined') {
    const { type } = action;
    types = [type, `${type}${successSuffix}`, `${type}${errorSuffix}`, `${type}${cancelSuffix}`];
  } else if (typeof action.types !== 'undefined') {
    types = action.types;
  } else {
    throw new Error(
      'Action which matched axios middleware needs to have "type" or "types" key which is not null'
    );
  }
  return types;
};

const notToRefreshList = [
  refreshToken.type.error,
  getToken.type.error,
  getTokenFinal.type.error,
  recoverEmail.type.error,
  passwordReset.type.error,
  forgotUserName.type.error,
  signUp.type.error,
  getMetaData.type.error,
];

const middlewareConfig = {
  onError: ({ action, next, dispatch, error }, actionOptions) => {
    let errorObject;
    if (
      error.response &&
      error.response.status === 401 &&
      !notToRefreshList.includes(action.types[2])
    ) {
      fallenRequests.set(action.types[0], action);
      dispatch(tokenExpiredAction());
    }

    if (!error.response) {
      errorObject = {
        data: error.message,
        status: 0,
      };
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('HTTP Failure in Axios', error);
      }
    } else {
      errorObject = error;
    }

    const nextAction = {
      type: getActionTypes(action, actionOptions)[2],
      error: errorObject,
      meta: { previousAction: action },
    };

    next(nextAction);
    return nextAction;
  },
};

export default middlewareConfig;
