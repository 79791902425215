import apiAction from '../apiActionCreator';
import { INSTANCE_TYPES } from '../../../helpers/utils';

const type = 'GET_REDIRECTION_TOKEN';

const config = {
  // url: '/auth/get-redirection-token',
  url: `/auth/get-redirection-token?instanceType=${INSTANCE_TYPES.IBI_SHOP}`,
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
