import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import {
  selectCalculatedPrice,
  selectElectronicSignments,
  selectEntityBillingDetails,
  selectEntityPaymentMethods,
  selectEntityUserDetails,
  selectEntityWallets,
  selectFilteredProducts,
  selectProductsWithSubscriptions,
} from './entities';
import { selectChosenPaymentMethod, selectShoppingCart, selectCouponId } from './global';
import { getPlanColors, BTC_PROMO_COUPON_ID } from '../../helpers/utils';

export const selectProducts = createSelector(
  selectProductsWithSubscriptions,
  (products) => products
);
export const selectProductsFiltered = createSelector(
  selectFilteredProducts,
  (products) => products
);

export const selectIbpProduct = createSelector(
  selectProductsWithSubscriptions,
  (productsWithSubs) => {
    const products = productsWithSubs;

    const ibi =
      products?.plans?.find(
        (plan) => plan?.urlParam?.toLowerCase() === 'ibi' || plan?.title?.toLowerCase() === 'ibi'
      ) || null;

    return ibi;
  }
);

export const selectIbiGateways = createSelector(selectIbpProduct, (ibi) => {
  if (!ibi) {
    return ['e-wallet'];
  }
  const availableGateways = ibi?.gatewayMethods.map((gateway) => gateway?.name);
  return availableGateways;
});

const plansUpdater = (plan) => {
  const { mainBg, text } = getPlanColors({ bgColor: plan.bgColor, color: plan.color });
  return {
    ...plan,
    price: plan.setupPrice,
    bgColor: mainBg,
    color: text,
  };
};

export const selectPlans = (productName) =>
  createSelector([selectProducts], (products) => {
    if (products) {
      const productWithPlans = products.find(
        (item) => item.name.toLowerCase() === productName.toLowerCase()
      );
      if (productWithPlans && productWithPlans.plans && productWithPlans.plans.length) {
        return productWithPlans.plans.map((item) => ({ ...plansUpdater(item) }));
      }
    }
    return null;
  });

export const selectCart = createSelector(
  [selectShoppingCart, selectCalculatedPrice, selectProducts],
  (
    cart,
    calculatedPrice
    // productList
  ) => {
    const productsInCart = Object.values(cart.products);
    const products = productsInCart;

    return {
      paymentAction: cart.paymentAction,
      subTotal: 0,
      products,
      productCount: products.length,
      processingFee: calculatedPrice?.processingFee || 0,
      showInitialDiscount: false,
    };
  }
);

export const selectIfIbpInTheCart = createSelector(selectCart, (cart) => {
  const isIbp = cart?.products.find(
    (plan) => plan?.urlParam?.toLowerCase() === 'ibi' || plan?.title?.toLowerCase() === 'ibi'
  );
  return !!isIbp;
});

export const selectAddressCountry = createSelector(selectEntityUserDetails, (userDetails) => {
  const billingAddresses =
    userDetails &&
    userDetails.billingAddresses &&
    userDetails.billingAddresses.length > 0 &&
    userDetails.billingAddresses;

  if (billingAddresses) {
    const primaryAddress = billingAddresses.find((item) => !!item.primary);
    const exigoAddress = billingAddresses.find((item) => !!item.exigo);
    return primaryAddress?.country || exigoAddress?.country || userDetails.country;
  }

  return null;
});

export const selectBillingAddresses = createSelector(selectEntityUserDetails, (userDetails) => {
  if (userDetails && userDetails.billingAddresses && userDetails.billingAddresses.length > 0) {
    return userDetails.billingAddresses.map((item) => ({
      ...item,
      firstName: item.firstName || userDetails.firstName,
      lastName: item.lastName || userDetails.lastName,
    }));
  }
  return [];
});

export const selectPaymentsWithDefault = createSelector(
  [selectEntityPaymentMethods, selectEntityBillingDetails],
  (methods, details) => {
    if (methods && details && details.defaultPaymentInstrument) {
      const { paymentCardId, payPalAccountId, bankAccountId } = details.defaultPaymentInstrument;

      const cards = methods.cards.map((item) => ({
        ...item,
        isDefault: item.id === paymentCardId,
      }));

      const paypal = methods.paypal.map((item) => ({
        ...item,
        isDefault: item.id === payPalAccountId,
      }));

      const bankAccounts = methods.bankAccounts.map((item) => ({
        ...item,
        brand: (item.brand || item.method || '').toUpperCase(),
        isDefault: item.id === bankAccountId,
      }));

      return {
        ...methods,
        cards,
        paypal,
        bankAccounts,
      };
    }
    return methods;
  }
);

export const selectSetPaymentMethod = createSelector(
  [selectChosenPaymentMethod, selectPaymentsWithDefault],
  (handleSelected, list) => {
    if (handleSelected) {
      return handleSelected;
    }
    if (list) {
      return (
        Object.values(list)
          .reduce((acc, val) => [...acc, ...val], [])
          .find((item) => !!item.isDefault) || {}
      );
    }
    return null;
  }
);

export const selectShowVatAmount = createSelector(selectSetPaymentMethod, (paymentMethod) => {
  if (paymentMethod) {
    return (
      paymentMethod.method === 'payment-card' &&
      paymentMethod.billingAddress.country &&
      paymentMethod.billingAddress.country.toUpperCase() === 'BR'
    );
  }
  return false;
});

export const showAutoRenewalMessage = createSelector(selectCart, (cart) => {
  if (cart.productCount) {
    return cart.products.some((item) => item.isRenew && !item.autopay);
  }
  return false;
});

export const selectEWalletsAmount = createSelector(selectEntityWallets, (wallets) => wallets);

// ? Showed pre authorize modal
export const selectPreAuthorizeModalCondition = createSelector(
  [selectSetPaymentMethod, selectEntityBillingDetails],
  (selectedMethod, billDetailsInfo) => {
    if (selectedMethod && billDetailsInfo) {
      const defaultPaymentInstrument = billDetailsInfo.defaultPaymentInstrument?.method;
      if (
        ['e-wallet', 'coinpayments'].includes(selectedMethod?.method) &&
        !defaultPaymentInstrument
      ) {
        return true;
      }
    }
    return false;
  }
);

export const selectIsNameChanged = createSelector(
  [selectElectronicSignments, selectBillingAddresses],
  (electronicSignments, billingAddresses) => {
    const primaryAddress = billingAddresses && billingAddresses.find((item) => !!item.primary);
    const agreement = !!electronicSignments?.length;
    const isNameChanged =
      agreement &&
      !(
        electronicSignments[0].data.firstName === primaryAddress.firstName &&
        electronicSignments[0].data.lastName === primaryAddress.lastName
      );

    return isNameChanged;
  }
);

export const selectAndCheckIfAgreementSignedForCurrentPlanInTheCart = createSelector(
  [selectCart, selectElectronicSignments, selectIsNameChanged],
  (cart, electronicSignments, isNameChanged) => {
    const agreement = !!electronicSignments?.length;

    if (!agreement || isNameChanged) {
      return false;
    }

    let foundSignedAgreement = false;
    const cartItemIds = cart?.products?.map((p) => p.id);
    const agreementItemIds = electronicSignments?.map((a) => a?.plans.map((p) => p.id));
    agreementItemIds.forEach((a, id) => {
      if (isEqual(sortBy(cartItemIds), sortBy(a))) {
        foundSignedAgreement = electronicSignments[id];
      }

      return null;
    });
    return foundSignedAgreement;
  }
);

export const selectConditionToShowPurchaseOrSignButton = createSelector(
  [
    selectCart,
    selectElectronicSignments,
    selectIsNameChanged,
    selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  ],
  (cart, electronicSignments, isNameChanged, isSigned) => {
    const agreement = !!electronicSignments?.length;
    const isEntry = cart?.products?.some(
      (product) => typeof product.isRenew === 'undefined' && typeof product.isUpdate === 'undefined'
    );

    if (cart?.products?.length) {
      if (isEntry && !!isSigned && agreement) {
        return false;
      }

      if (isEntry || isNameChanged) {
        return true;
      }
    }

    if (!cart?.products?.length) {
      if ((isEntry && !agreement) || isNameChanged) {
        return true;
      }

      return true;
    }

    return false;
  }
);

// ? Showed CVV modal
export const selectPaymentCardForCvvConfirm = createSelector(
  [selectSetPaymentMethod, selectPaymentsWithDefault],
  (selectedMethod, allMethods) => {
    if (allMethods) {
      let card = null;
      const defaultCard = allMethods.cards.find((item) => item.isDefault);

      if (selectedMethod && selectedMethod.method === 'payment-card') {
        card = selectedMethod;
      }

      if (!selectedMethod && defaultCard) {
        card = defaultCard;
      }

      if (card) {
        return card;
      }

      return null;
    }

    return null;
  }
);

const selectDefaultPaymentMethod = createSelector(selectPaymentsWithDefault, (methods) => {
  if (methods) {
    return Object.values(methods).find((method) => method.isDefault);
  }
  return null;
});

// ? Showed 10% coupon discount
export const selectCoinPaymentDiscount = createSelector(
  [selectSetPaymentMethod, selectDefaultPaymentMethod, selectEntityUserDetails, selectCouponId],
  (selectedMethod, defaultMethod, userDetails, couponCode) => {
    if (userDetails?.country !== 'US') {
      return false;
    }

    const defaultCard = defaultMethod?.method === 'payment-card';
    const cardSelected = selectedMethod?.method === 'payment-card';

    //! FOR card only
    if (cardSelected || (!selectedMethod && defaultCard)) {
      return {
        message: 'Select Coin Payments method for 10% discount ',
        links: null,
      };
    }

    //! NO methods
    // const noMethodsSelected = !defaultMethod && !selectedMethod?.method;
    //
    // if (noMethodsSelected) {
    //   return {
    //     message: 'Add BitCoin Payments method for 10% discount ',
    //     links: null,
    //   };
    // }

    // TODO: move to links

    if (couponCode === BTC_PROMO_COUPON_ID) {
      return {
        messages: null,
        links: {
          buy: {
            label: 'Buy BTC Here',
            url: 'https://changelly.com/buy?ref_id=7og15wixrcg4zqp1',
          },
          guide: {
            label: 'Follow this Easy Guide',
            url: 'btc-guide',
          },
        },
      };
    }

    return false;
  }
);

export const selectIfUserNeedToSignAgreement = createSelector(
  [selectElectronicSignments],
  (electronicSignments) => electronicSignments?.requestSign
);
