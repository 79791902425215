import { all, call, put, takeLatest } from 'redux-saga/effects';
import { isClient } from '../helpers/utils';
import refreshToken from '../services/api/actions/refreshToken';
import { getTokens, setTokens, TOKEN_EXPIRED } from '../services/api/tokenHandler';
import fallenRequests from '../services/api/fallenRequests';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import { logOut } from '../store/actions';

let processing = false;

function* tokenFlowSaga({ type, payload }) {
  switch (type) {
    case TOKEN_EXPIRED: {
      const tokens = getTokens()
      if (!processing && tokens.refreshToken && tokens.accessToken) {
        processing = true;
        yield put(refreshToken.action());
      }
      break;
    }
    case refreshToken.type.start: {
      // eslint-disable-next-line no-console
      console.warn('RefreshToken.start');
      break;
    }
    case refreshToken.type.success: {
      const { accessToken, refreshToken: refrToken } = payload.data;

      yield call(setTokens, { accessToken, refreshToken: refrToken });

      const actions = fallenRequests.getAll();
      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        yield put(action);
      }
      processing = false;
      break;
    }
    case refreshToken.type.error: {
      processing = false;
      if (isClient) {
        localStorage.clear();
      }
      yield put(logOut());
      yield call(createNavigateTo(pageLinks.signIn));
      break;
    }
    default:
      break;
  }
}

function* refreshTokenSaga() {
  yield all([yield takeLatest([...refreshToken.types, TOKEN_EXPIRED], tokenFlowSaga)]);
}

export default refreshTokenSaga;
