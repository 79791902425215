import apiAction from '../apiActionCreator';

const type = 'GET_TOKEN';

const config = {
  url: '/auth/signin',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
