import { call } from 'redux-saga/effects';

const loadScript = (GOOGLE_ID) => {
  const imported = document.createElement('script');
  imported.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ID}`;

  document.head.appendChild(imported);

  const script = document.createElement('script');
  script.text = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${GOOGLE_ID}');`;

  document.head.appendChild(script);
};

function* createAnalyticScript() {
  if (typeof window !== 'undefined') {
    yield call(loadScript, process.env.GATSBY_GOOGLE_ANALYTIC_KEY);
  }
}

export default function* analyticsSaga() {
  yield call(createAnalyticScript);
}
