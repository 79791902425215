const sortFn = (a, b) => {
  const firstPriority = a.priority;
  const secondPriority = b.priority;
  return firstPriority - secondPriority;
};

function arrayMoveItem(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

module.exports = function productsResolver(products) {
  const productItems = ['ibi'];
  const otherCategory = products.find(p => p?.category?.toLowerCase() === 'other');
  const ibiProduct = otherCategory?.subcategories?.find(cat => productItems.includes(cat?.urlParam));
  return ibiProduct;
};
