export default (schema) => {
  const { actionType, dataFormat, ...rest } = schema;

  const type = {
    start: `${actionType}`,
    success: `${actionType}/SUCCESS`,
    error: `${actionType}/ERROR`,
  };

  const types = Object.values(type);

  const action = (data = {}) => ({
    types,
    payload: {
      request: {
        data,
        ...rest,
      },
    },
  });

  const withQuery = (string) => ({
    action: (data) => ({
      types,
      payload: {
        request: {
          data,
          ...rest,
          url: rest.url + string,
        },
      },
    }),
  });

  return {
    type,
    types,
    withQuery,
    action,
  };
}
