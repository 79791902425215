import { isProduction } from '../helpers/utils';

const loaderPrefix = 'loader_button';

export const ButtonLoaderTypes = {
  PURCHASE: `purchase_${loaderPrefix}`,
};

const paypalGetawaysProduction = {
  paypalCommon: 'paypal-live',
  paypalCard: 'paypal-Card-02',
};

const paypalGetawaysStaging = {
  paypalCommon: 'demo-sandbox-gateway',
  paypalCard: 'paypal',
};

export const PAYPAL_GETAWAYS = isProduction ? paypalGetawaysProduction : paypalGetawaysStaging;
