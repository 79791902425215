import apiAction from '../apiActionCreator';

const type = 'GET_GATEWAY_METHODS';
// getGatewayMethods

const config = {
  url: '/v1/order/gateway-method/list',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
