import apiAction from '../apiActionCreator';
import { isAddonInstance } from '../../../helpers/utils';

const type = 'GET_COUPON_LIST';

const config = {
  url: `/coupon/list?addons=${!!isAddonInstance}`,
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
