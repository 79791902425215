import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { pageLinks } from './navigation';
import { useStorage } from '../services/storage';
import { getCountryData } from './countries';
// eslint-disable-next-line import/no-cycle
import { getLinkToCorrectShopByCountry } from './instanceHandler';

export const anyLetterRegExp = /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;
export const anyLetterErrorMessage = 'These characters are not supported';
export const today = new Date();
export const minDate = new Date('1900-01-01');

export const isProduction = process.env.GATSBY_INSTANCE_TYPE === 'prod';

export const BTC_PROMO_COUPON_ID = 'BTC-PROMO';

export const instanceList = {
  shopIbi: 'onlineLine',
  ibi: 'ibiusa',
  ibiApi2: 'ibiusatwo',
  ibiShopApi2: 'ibiworldtwo',
};

export const isOnlineLineInstance = ['br', 'onlineLine'].includes(process.env.GATSBY_INSTANCE_NAME);
export const isRetailAddonInstance = process.env.GATSBY_INSTANCE_TYPE === 'retail';
export const isAddonInstance = process.env.GATSBY_INSTANCE_NAME === 'addon';
export const isIbiUsaInstance = process.env.GATSBY_INSTANCE_NAME === instanceList.ibi;
export const isNewIbiUsaInstance = process.env.GATSBY_INSTANCE_NAME === instanceList.ibiApi2;
export const isIbiInstances = isIbiUsaInstance || isNewIbiUsaInstance;
export const isNewApiInstances = isNewIbiUsaInstance;

export const availableLanguages = [
  {
    value: 0,
    locale: 'gb',
    label: 'English (United Kingdom)',
  },
  {
    value: 6,
    locale: 'fr',
    label: 'French',
  },
  {
    value: 2,
    locale: 'de',
    label: 'German',
  },
  {
    value: 16,
    locale: 'pt',
    label: 'Portuguese',
  },
  {
    value: 1,
    locale: 'es',
    label: 'Spanish',
  },
  {
    value: 9,
    locale: 'th',
    label: 'Thai',
  },
  {
    value: 204,
    locale: 'cn',
    label: 'Chinese',
  },
  {
    value: 20,
    locale: 'sk',
    label: 'Slovak',
  },
  // {
  //   value: 17,
  //   locale: 'it',
  //   label: 'Italian',
  // },
];

export const getLanguage = (localeOrId) =>
  availableLanguages.find(
    (language) => language.value === localeOrId || language.locale === localeOrId
  );

export const colorsResolver = (planName) => {
  switch (planName) {
    case 'learn': {
      return 'blue';
    }
    case 'live': {
      return 'purple';
    }
    case 'elite': {
      return 'red';
    }
    default:
      return 'purple';
  }
};

export const getPaymentTitle = (method) => {
  if (method === 'ewallet' || method === 'e-wallet') {
    return 'E-wallet';
  }
  if (method === 'bitpay') {
    return 'BitPay';
  }
  if (method === 'paypal') {
    return 'PayPal';
  }
  if (method === 'ebanx') {
    return 'EBANX';
  }
  if (method === 'top') {
    return 'Top';
  }
  if (method === 'card' || method === 'payment-card') {
    return 'Credit & Debit Card';
  }
  if (method === 'ach') {
    return 'Plaid';
  }
  if (method === 'coinpayments') {
    return 'Pay With BITCOIN/Crypto Currency';
  }
  if (method === 'paypal-card') {
    return 'USA alternative checkout';
  }
  if (method === 'astroPay') {
    return 'AstroPay Card';
  }
  if (method === 'cash-deposit') {
    return 'Cash Deposit';
  }
  if (method === 'bank-transfer') {
    return 'Bank Transfer';
  }
  return '';
};

export const SHOPS_METHODS = {
  eWallet: 'e-wallet',
  bankTransfer: 'bank-transfer',
  astroPay: 'astroPay',
  card: 'card',
  ach: 'ach',
  paypal: 'paypal',
  cashDeposit: 'cash-deposit',
};

const adminShopPaymentMethods = {
  'e-wallet': SHOPS_METHODS.eWallet,
  'bank-transfer': SHOPS_METHODS.bankTransfer,
  'AstroPay Card': SHOPS_METHODS.astroPay,
  paypal: SHOPS_METHODS.paypal,
  'payment-card': SHOPS_METHODS.card,
  ach: SHOPS_METHODS.ach,
  'cash-deposit': SHOPS_METHODS.cashDeposit,
};

const sortPositions = {
  'e-wallet': 2,
  paypal: 3,
  card: 4,
  'paypal-card': 5,
};

export const sortPaymentMethods = (a, b) => {
  const firstMethod = sortPositions[a] || 6;
  const secondMethod = sortPositions[b] || 6;

  return firstMethod - secondMethod;
};

export const convertToShopPaymentMethod = (adminMethods) => {
  let formatted = [];

  adminMethods?.forEach((name) => {
    if (adminShopPaymentMethods[name]) {
      formatted.push(adminShopPaymentMethods[name]);
    }
  });

  if (formatted.includes(SHOPS_METHODS.coinPayments) && formatted.includes(SHOPS_METHODS.eWallet)) {
    formatted = new Set([SHOPS_METHODS.eWallet, SHOPS_METHODS.coinPayments, ...formatted]);
  }

  return [...formatted];
};

export const isClient = typeof window === 'object';

export const isUserLogged = () => !!useStorage().getItem('AT');

export const checkIfPathProtected = (path) => {
  if (isClient && !isUserLogged() && path === pageLinks.checkout) {
    return pageLinks.signIn;
  }
  return path;
};

export const getMonthByNumber = (num) =>
  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][num];

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getShopName = (param) => {
  const name = param.toLowerCase();
  if (name.includes('luxe')) {
    return 'live';
  }
  if (name.includes('learn')) {
    return 'learn';
  }
  return name;
};

export const getSiteUrl = () => {
  if (isClient) {
    return window.location.origin;
  }
  return '';
};

export const checkConsecutives = (word, length) => {
  if (word && word.length >= length) {
    for (let i = 0; i <= word.length; i += 1) {
      // iterating over 3-char sequences
      const charCode = word[i] && word[i].charCodeAt(0);
      const charCodeTwo = word[i + 1] && word[i + 1].charCodeAt(0);
      const charCodeThree = word[i + 2] && word[i + 2].charCodeAt(0);

      if (charCodeTwo - charCode === 1 && charCodeThree - charCode === 2) {
        return true;
      }
    }
  }

  return false;
};

export const hasConsecutiveSymbols = (value = '') => {
  const numbers = value.replace(/\W/g, ' ').match(/(\d+)/g);
  const letters = value.replace(/\W/g, ' ').match(/[a-zA-Z]+/g);

  const isConsecutiveNumbers = numbers && numbers.find((item) => checkConsecutives(item, 3));
  const isConsecutiveLetters = letters && letters.find((item) => checkConsecutives(item, 3));

  return !(isConsecutiveNumbers || isConsecutiveLetters);
};

export function hasLoginPassSameString() {
  return this.parent.loginName !== this.parent.loginPassword;
}

export const hasRequiredSymbols = (value = '') =>
  // 1 letter / 1 uppercase / 1 number / 1 special character
  value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/);

export const hasSpecialCharacters = (value = '') => value.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/);

export const hasRestrictedSymbols = (value = '') => {
  const regx = /^[a-zA-Z0-9-!$%^&*()_+|~=`{}:;<>?,.@#]*$/;
  return regx.test(value);
};

export const hasCommonWords = (value, pattern) => !value.toLowerCase().match(pattern);

export const getInstanceName = () => 'Be Backoffice';

export const getPaymentAction = (plan) => {
  if (plan.isRenew) {
    return 'RENEW';
  }
  if (plan.isUpgrade) {
    return 'UPGRADE';
  }

  return 'INITIAL';
};

export const getAction = (isRenew) => {
  if (isRenew) {
    return 'RENEW';
  }

  return 'INITIAL';
};

export const INSTANCE_TYPES = {
  MHA_LOGIN: 'mha_login',
  TRAVEL_LOGIN: 'travel_login',
  SHOP: 'shop',
  CUSTOMER_PORTAL: 'customer_portal',
  IBI_SHOP: 'ibi_shop',
  KEY_REPORT: 'key_report',
  BE_PAY: 'be_pay',
};

export const INITIATOR = {
  ADMIN: 'admin',
  SHOP: 'shop',
  ADDON_SHOP: 'addon_shop',
  RB: 'report',
  CP: 'CP',
  TP: 'TP',
  MHA: 'MHA',
  PP: 'PP',
};

export const getPortalLink = (country) => {
  // if (isAddonInstance) {
  //   return isClient && window.location.origin.replace('stg.marketplace', 'start.stg');
  // }
  const shopUrl = getLinkToCorrectShopByCountry(country)?.split(
    isProduction ? '//' : '//shop.stg.'
  );
  // eslint-disable-next-line no-use-before-define
  shopUrl.splice(1, 0, isProduction ? '//start.' : '//start.stg.');

  return shopUrl.join('');
};

export const formatWithoutTimeZone = (date) =>
  moment.parseZone(date, 'YYYY-MM-DDTHH:mm:ss.SSS', false).format('YYYY-MM-DDTHH:mm:ss.SSS');

export const transformCartToItems = (data, withSamePlanLogic = true) => {
  let sameLevelPlans = false;

  if (withSamePlanLogic && data.length >= 2) {
    sameLevelPlans = data[0].level === data[1].level;
  }

  const result = data.flatMap((item) => {
    const plans = item.rebillyPlanIds || item.plans;
    const itemIsCombo = item.isCombo;

    if (itemIsCombo && item.comboItems) {
      return item.comboItems;
    }

    return {
      planId: item.id,
      action: getAction(item?.isRenew || item?.purchased),
      ...(item.topUpAmount ? { topUpAmount: item.topUpAmount } : {}),
    };
  });

  return result;
};

export const externalRedirect = (url, options = {}) => {
  if (options.skipBeforeUnload) {
    window.onbeforeunload = null;
  }
  const link = document.createElement('a');
  link.href = url;
  link.target = options.target || '_self';

  link.click();
};

export const installmentCountries = ['BR'];

export const getPaymentsByCountry = () => ['e-wallet'];

export const filterPaymentMethodsByGateway = (shoppingCart, allMethods) => {
  if (!shoppingCart.productCount) {
    return allMethods.map((methodName) => ({ name: methodName, priority: 1 }));
  }

  const methodsByProducts = shoppingCart?.products.reduce((acc, product) => {
    product?.gatewayMethods?.forEach((m) => {
      if (!acc.includes(m.name)) {
        acc.push({ name: m.name, priority: m.priority });
      }
    });
    return convertToShopPaymentMethod(acc);
  }, []);

  return methodsByProducts.length
    ? methodsByProducts.filter((name) => allMethods.includes(name))
    : [];
};

export const getPaymentMethods = (products, countryName) => {
  const isAnnual = products.some((p) => p.isAnnual);
  if (isAnnual) {
    return ['bitpay', 'e-wallet'];
  }

  return getPaymentsByCountry(countryName);
};

export const normalizeLatinLetters = (text = '') =>
  text
    .normalize('NFKD')
    .replace(/[\u0080-\uF8FF\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]/g, '') || '';

export const isEqualArrayStrings = (a, b) => isEqual(a.sort(), b.sort());

const isSafariMobile = isClient && /iPhone/i.test(navigator.userAgent);

export const scrollToMiddle = (id) => {
  if (isClient) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      if (isSafariMobile) {
        const height = window.innerHeight;
        let top = height;

        if (height < 600) {
          top = height + 650;
        }

        if (height > 600 && height < 700) {
          top = height + 550;
        }

        if (height > 700) {
          top = height + 250;
        }

        window.scroll({
          top,
          behavior: 'smooth',
        });
      }
    }
  }
};

export const colorLuminance = (hexColor, luminance) => {
  let hex = hexColor;
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const lum = luminance || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;

  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const getPlanColors = ({ bgColor, color }) => ({
  mainBg: colorLuminance(bgColor, 0),
  text: colorLuminance(color, 0),
  mainDark: colorLuminance(bgColor, -0.1),
  mainLabel: colorLuminance(bgColor, -0.15),
  discount: colorLuminance(bgColor, -0.35),
});

export function createDocLink(docPath, countryCode) {
  const countryData = getCountryData(countryCode);
  const countryName = countryData ? countryData.name : null;
  if (!countryName) {
    return docPath;
  }
  const searchParams = new URLSearchParams(`country=${countryName}`);
  return `${docPath}?${searchParams.toString()}`;
}

export const filterComboIncludedPlans = (plan, shoppingCart) => {
  const { title } = plan;

  const copiedProducts = { ...shoppingCart.products };

  return { ...copiedProducts, [title]: plan };
};

export const doNotShowRenewalPriceAddons = isRetailAddonInstance
  ? []
  : ['ADD-RET-US30', 'ADD-US30'];

export const getEnvConfig = () => ({
  GATSBY_API_URL: process.env.GATSBY_API_URL,
  GATSBY_REBILLY_KEY: process.env.GATSBY_REBILLY_KEY,
  GATSBY_INSTANCE_NAME: process.env.GATSBY_INSTANCE_NAME,
  GATSBY_INSTANCE_TYPE: process.env.GATSBY_INSTANCE_TYPE,
  GATSBY_PORTAL_LINK: process.env.GATSBY_PORTAL_LINK,
  GATSBY_GB_PROD_URL: process.env.GATSBY_GB_PROD_URL,
  GATSBY_BRAZIL_PROD_URL: process.env.GATSBY_BRAZIL_PROD_URL,
});

// eslint-disable-next-line consistent-return
const selectRegex = (country, number) => {
  if (number === 1) {
    // eslint-disable-next-line default-case
    switch (country.toLowerCase()) {
      case 'us':
      case 'ca':
        return /^(\d*)/;
    }
  }

  if (number === 2) {
    // eslint-disable-next-line default-case
    switch (country.toLowerCase()) {
      case 'us':
        return /^(\d\d\d)(\d{0,2})/;
      case 'ca':
        return /^(\d\d\d)(\d{0,3})/;
    }
  }

  if (number === 3) {
    // eslint-disable-next-line default-case
    switch (country.toLowerCase()) {
      case 'us':
        return /^(\d\d\d)(\d{2})(\d{0,4}).*/;
      case 'ca':
        return /^(\d\d\d)(\d{3})(\d{0,3}).*/;
    }
  }
};

export const convertValueToSsn = (value, country) => {
  const ssnValue = value;
  const france = country === 'fr';
  const usa = country === 'us';
  const canada = country === 'ca';
  const world = !france && !usa && !canada;

  if (world || canada || usa) {
    const newSsnValue = ssnValue.replace(/-/g, '').replace(/[a-zA-Z]/g, '');
    if (newSsnValue.length > 9) {
      return newSsnValue.slice(0, 9);
    }
    return newSsnValue;
  }

  return ssnValue;
};

export const convertSsnToValue = (ssn, country) => {
  let ssnValue = ssn;
  const france = country.toLowerCase() === 'fr';
  const usa = country.toLowerCase() === 'us';
  const canada = country.toLowerCase() === 'ca';
  const world = !france && !usa && !canada;

  if (!ssnValue) {
    return null;
  }

  if (france) {
    return ssnValue;
  }

  if ((usa || canada || world) && ssnValue?.length > 9) {
    ssnValue = ssnValue?.slice(0, 9);
  }

  if (canada || usa) {
    let r = ssnValue;
    if (r.length > 9) {
      r = r.replace(selectRegex(country, 3), '$1-$2-$3');
    }
    if (r.length > 4) {
      r = r.replace(selectRegex(country, 3), '$1-$2-$3');
    } else if (r.length > 2) {
      r = r.replace(selectRegex(country, 2), '$1-$2');
    } else {
      r = r.replace(selectRegex(country, 1), '$1');
    }
    return r;
  }

  return ssnValue;
};

export const SIGNING_AUDIT_TRAIL_TYPE = {
  INITIATED: {
    step: 1,
    type: 'initiated',
  },
  DOCUMENT_VIEWED: {
    step: 2,
    type: 'document_viewed',
  },
  CANCELED: {
    step: 4,
    type: 'canceled',
  },
  DOCUMENT_SIGNED: {
    step: 3,
    type: 'document_signed',
  },
  SIGNING_COMPLETED: {
    step: 6,
    type: 'signing_completed',
  },
  SIGNING_NOT_FINISHED: {
    step: 5,
    type: 'signing_not_finished',
  },
};

export const checkIfUserIsIbp = (roles) => roles?.some((role) => role?.name === 'IBP');

export const downloadFile = (url, filename) => {
  fetch(url).then((t) =>
    t.blob().then((b) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', filename);
      a.click();
    })
  );
};

export const normalizeSocialLinks = (socialLinksArray, prismicData) => {
  const links = socialLinksArray.reduce((acc, link) => {
    const url = prismicData[link?.name]?.url;
    if (url) {
      acc.push({
        ...link,
        url,
      });
    }

    return acc;
  }, []);

  return links;
};

export const setLanguageToLocalStorage = (language) => {
  if (isClient) {
    return window?.localStorage?.setItem('selectedLanguage', language);
  }

  return null;
};

export const getLiveChatParams = (userDetails, subscriptions) => {
  if (!userDetails) {
    return null;
  }

  const roles = userDetails.roles?.map((role) => role && role?.name)?.join(', ') || '-';
  const subs = subscriptions?.map((subscription) => subscription.level)?.join(', ') || '-';

  return [
    { name: 'Exigo ID', value: userDetails.customerId },
    { name: 'Legacy Rank', value: roles },
    { name: 'Enroller ID', value: userDetails?.enroller?.customerId || '-' },
    { name: 'Country Code', value: userDetails.country },
    { name: 'Email Address', value: userDetails.email },
    { name: 'Language', value: userDetails.languageId },
    { name: 'Existing Rebilly subscription', value: subs },
  ];
};
