import { countries } from 'countries-list';

export const isValidCountryCode = (code) =>
  // eslint-disable-next-line no-prototype-builtins
  typeof code === 'string' && countries.hasOwnProperty(code.toUpperCase());

export const getCountryData = (code = '') => {
  const kzSupportedCode = code?.toUpperCase() === 'KZ' ? 'RU' : code;
  if (!isValidCountryCode(kzSupportedCode)) {
    return null;
  }
  return countries[kzSupportedCode.toUpperCase()];
};

export const getAllCountryCodes = () => Object.keys(countries).map((code) => code.toLowerCase());
